.modal-title {
    text-align: center;
    color: #fff;
    font-size: 18px;
    height: 55px;
    line-height: 55px;
    overflow: hidden;
    background: #297fb8;
}
.modal-title h1 {
    color: #fff;
    font-size: 1rem;
}
.modal-symbol {
    text-align: center;
}
.modal-symbol img {
    margin-bottom: 20px;
}